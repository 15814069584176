<template>
    <div class="home">
      <div class="container">
        <div>
          <Top :activeTitle="activeTitle" :list="list" />
          <img width="100%" src="../assets/image/manual.png" alt="">
          <a :href="apk.url" class="box"><img width="100%" src="../assets/image/box.png" alt=""></a>
        </div>
      </div>
        <FooterNav  :activeIndex="active"/>
        <FooterText />
    </div>
      
      
      
  </template>
  
  <script>
    import FooterNav from "../components/FooterNav.vue";
    import Top from "../components/Top.vue";
    import FooterText from "../components/FooterText.vue";

  export default {
    components: {
        FooterNav,
        Top,
        FooterText
    },
    data() {  
      return {
        active: 1,
        activeTitle:1,
        apk:[],
        isHot:1,
        list:[
          {name:'เติมเงินทันที',url:'/good'},
          {name:'ช่วยเหลือ',url:'/help'},
        ]
  
      };
    },
    computed: {

    },
    mounted() {
  
    },
    created() {
      this.getHotApk()
    },
    methods:{

      getHotApk(){
          this.service.post('Home/getHotApk',{is_hot: this.isHot})
          .then((res) => {
              this.apk = res.data.data
          }) 
      },
    }
  };
  </script>
  
  <style scoped lang="less">
  
    .main_content{
      .main_title{
        color: #FFFFFF;
        font-size: 32px;
        line-height: 42px;
      }
      .main_detail{
        overflow: hidden;
        color: #FFFFFF;
        font-size: 28px;
        line-height: 32px;
      }
      .main_img{
        img{
          width: 690px;
          height: 980px;
        }
      }
      .box{
        width: 690px;
        height: 310px;
      }
    }
  </style>
  
  